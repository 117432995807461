import React, { useState, useEffect, useRef, useCallback } from 'react';
import './App.css';

const CELL_SIZE = 20;
const BOARD_SIZE = 20;
const MOVE_INTERVAL = 150; // milliseconds between moves

function App() {
  const [snake, setSnake] = useState([{ x: 10, y: 10 }]);
  const [food, setFood] = useState(null);
  const [direction, setDirection] = useState('RIGHT');
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [nextDirection, setNextDirection] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const gameboardRef = useRef(null);
  const lastMoveTimeRef = useRef(0);
  const animationFrameId = useRef(null);

  const moveSnake = useCallback((currentTime) => {
    if (isPaused) return;
    if (currentTime - lastMoveTimeRef.current >= MOVE_INTERVAL) {
      setSnake((prevSnake) => {
        const newHead = { ...prevSnake[0] };
        const currentDirection = nextDirection || direction;

        switch (currentDirection) {
          case 'UP':
            newHead.y = (newHead.y - 1 + BOARD_SIZE) % BOARD_SIZE;
            break;
          case 'DOWN':
            newHead.y = (newHead.y + 1) % BOARD_SIZE;
            break;
          case 'LEFT':
            newHead.x = (newHead.x - 1 + BOARD_SIZE) % BOARD_SIZE;
            break;
          case 'RIGHT':
            newHead.x = (newHead.x + 1) % BOARD_SIZE;
            break;
          default:
            // No change in direction
            break;
        }

        if (prevSnake.slice(1).some(segment => segment.x === newHead.x && segment.y === newHead.y)) {
          setGameOver(true);
          return prevSnake;
        }

        if (food && newHead.x === food.x && newHead.y === food.y) {
          setFood(null);
          setScore(prevScore => prevScore + 1);
          return [newHead, ...prevSnake];
        }

        setDirection(nextDirection || direction);
        setNextDirection(null);
        lastMoveTimeRef.current = currentTime;

        return [newHead, ...prevSnake.slice(0, -1)];
      });
    }

    if (!gameOver) {
      animationFrameId.current = requestAnimationFrame(moveSnake);
    }
  }, [direction, nextDirection, food, gameOver, isPaused]);

  useEffect(() => {
    if (!gameOver) {
      animationFrameId.current = requestAnimationFrame(moveSnake);
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [moveSnake, gameOver]);

  const generateFood = useCallback(() => {
    const isOccupied = (x, y) => snake.some(segment => segment.x === x && segment.y === y);
    let newFood;
    do {
      newFood = {
        x: Math.floor(Math.random() * BOARD_SIZE),
        y: Math.floor(Math.random() * BOARD_SIZE),
      };
    } while (isOccupied(newFood.x, newFood.y));
    return newFood;
  }, [snake]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (gameOver) return;
      
      switch (e.key) {
        case 'ArrowUp':
          setNextDirection((prev) => direction !== 'DOWN' ? 'UP' : prev);
          break;
        case 'ArrowDown':
          setNextDirection((prev) => direction !== 'UP' ? 'DOWN' : prev);
          break;
        case 'ArrowLeft':
          setNextDirection((prev) => direction !== 'RIGHT' ? 'LEFT' : prev);
          break;
        case 'ArrowRight':
          setNextDirection((prev) => direction !== 'LEFT' ? 'RIGHT' : prev);
          break;
        default:
          // Ignore other keys
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [gameOver, direction]);

  const getSegmentStyle = (segment, index) => {
    const style = {
      left: segment.x * CELL_SIZE,
      top: segment.y * CELL_SIZE,
      width: CELL_SIZE,
      height: CELL_SIZE,
    };

    return style;
  };

  const restartGame = () => {
    setSnake([{ x: 5, y: 5 }]);
    setDirection('RIGHT');
    setFood(null);
    setGameOver(false);
    setScore(0);
    lastMoveTimeRef.current = 0;
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }
    animationFrameId.current = requestAnimationFrame(moveSnake);
  };

  useEffect(() => {
    if (!food) {
      setFood(generateFood());
    }
  }, [food, generateFood]);

  useEffect(() => {
    console.log('Score state updated:', score);
  }, [score]);

  const togglePause = () => {
    setIsPaused(prevPaused => !prevPaused);
    if (isPaused) {
      animationFrameId.current = requestAnimationFrame(moveSnake);
    } else {
      cancelAnimationFrame(animationFrameId.current);
    }
  };

  return (
    <div className="App">
      <h1>Snake Game</h1>
      <div className="game-info">
        <div className="score">Score: {score}</div>
        {!gameOver && (
          <div className="button-container">
            <button onClick={togglePause}>{isPaused ? 'Resume' : 'Pause'}</button>
          </div>
        )}
      </div>
      <div 
        ref={gameboardRef}
        className="game-board" 
        style={{ 
          width: BOARD_SIZE * CELL_SIZE,
          height: BOARD_SIZE * CELL_SIZE,
          backgroundColor: '#E8F5E9'
        }}
      >
        {snake.map((segment, index) => (
          <div
            key={`${segment.x}-${segment.y}-${index}`}
            className={`snake-segment ${index === 0 ? 'snake-head' : 'snake-body'}`}
            style={getSegmentStyle(segment, index)}
          >
            {index === 0 && (
              <>
                <div className="snake-eye snake-eye-left"></div>
                <div className="snake-eye snake-eye-right"></div>
              </>
            )}
          </div>
        ))}
        {food && (
          <div
            className="food"
            style={{
              left: food.x * CELL_SIZE,
              top: food.y * CELL_SIZE,
              width: CELL_SIZE,
              height: CELL_SIZE,
            }}
          />
        )}
      </div>
      {(gameOver || isPaused) && (
        <div className="game-over">
          <h2>{gameOver ? 'Game Over!' : 'Game Paused'}</h2>
          <p>Your score: {score}</p>
          <div className="button-container">
            {gameOver ? (
              <button onClick={restartGame}>Play Again</button>
            ) : (
              <button onClick={togglePause}>Resume</button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;